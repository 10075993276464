import { render, staticRenderFns } from "./Preview.vue?vue&type=template&id=efff25a8&scoped=true&"
import script from "./Preview.vue?vue&type=script&lang=js&"
export * from "./Preview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efff25a8",
  null
  
)

export default component.exports